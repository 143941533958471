import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from "axios";
import {AppContext} from "../../Contexts/AppContext";
import {fileName} from "./fileName";

class TosSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: props.docList,
            lang: props.lang,
            version: ''
        };
    }

    handleChange = (event) => {
        let tosFileName = 'ru_Пользовательское соглашение_.docx'

        if (event.target.name === 'name') {
            tosFileName = event.target.value
        }

        if (event.target.name === 'name') {
            let path = 'tos'
            switch (fileName[this.state.lang].indexOf(tosFileName.split('_')[1])) {
                case 0:
                    path = "purchase_policy";
                    break;
                case 1:
                    path = "privacy_policy";
                    break;
                case 2:
                    path = "tos";
                    break;
                case 3:
                    path = "cookies";
                    break;
            }
            if (typeof window !== 'undefined') {
                window.history.pushState(
                    {
                        path
                    },
                    '',
                    `${path}`
                );
            }
        }

        if (event.target.name === 'lang') {
            let oldFileNme = (this.context.tosFileName.split('_') ?? ['',''] )[1];
            let oldFileIndex = fileName[this.state.lang].indexOf(oldFileNme);

            this.setState({
                lang: event.target.value
            })

            let newFileName = fileName[event.target.value][oldFileIndex]
            // делаем смену имени файла.
            tosFileName = `${event.target.value}_${newFileName}_${this.state.version}.docx`
        }

        this.context.changeFileName(tosFileName)
    };

    // fetch data
    static fetchData() {
        console.log('TosSelect::fetchData')
        return axios.get('https://api.tos.api.evernum.ru/s3', {
            // return axios.get('http://127.0.0.1:8080/s3', {
            headers: {'Accept': 'application/json'},
        }).then(response => {
            return {content: response.data.data}
        });
    }

    // when component mounts, fetch data
    componentDidMount() {
        console.log('TosSelect::componentDidMount')
        if (this.state.content == null) {
            TosSelect.fetchData().then(content => {

                this.setState({
                    content: content.content
                })
            });
        }
    }

    render() {
        if (this.state.content === null ) {
            return ('');
        }
        return (
            <div>
                <FormControl sx={{
                    m: 1,
                    minWidth: 120,
                    '@media screen and (max-width: 900px)': {
                        width: 'calc(100% - 16px)',
                    }
                }}>
                    <InputLabel id="file-name-label">Язык.</InputLabel>
                    <Select
                        labelId="file-lang-label"
                        value={this.state.lang}
                        defaultValue={'ru'}
                        displayEmpty
                        label="lang"
                        name={'lang'}
                        onChange={this.handleChange}
                    >
                        {
                            Object.keys(this.state.content ?? {ru: ''}).map((item, key) => {
                                return(
                                    <MenuItem key={key} value={item}>{item}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl sx={{
                    m: 1,
                    minWidth: 120,
                    '@media screen and (max-width: 900px)': {
                        width: 'calc(100% - 16px)',
                    }
                }}>
                    <InputLabel id="file-name-label">Док.</InputLabel>
                    <Select
                        labelId="file-name-label"
                        value={this.context.tosFileName}
                        defaultValue={'ru_Пользовательское соглашение_.docx'}
                        displayEmpty
                        label="name"
                        name={'name'}
                        onChange={this.handleChange}
                    >
                        {
                            this.state.content?.[this.state.lang][this.state.version].map((item, key) => {
                                return(
                                    <MenuItem key={key} value={item.fileName}>{item.name} ({item.time === '' ? 'текущая версия' : item.time})</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        );
    }
}

TosSelect.contextType = AppContext;
export default TosSelect;
