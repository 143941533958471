import {Tos} from "../baseTos";
import {fileName} from "../baseTos/fileName";

export class PrivacyPolicy extends Tos {
    componentDidMount() {
        console.log('PrivacyPolicy::componentDidMount')
        const query = new URLSearchParams(window.location.search);
        if (this.state.isLoading) {
            Tos.fetchData(query, this.state.lang, 1).then(content => {
                this.setState({
                    isLoading: false,
                    content: content.content,
                    fileName: content.name
                });
            });
        }
        const name = `${this.state.lang}_${fileName[this.state.lang][1]}_.docx`
        this.context.changeFileName(name)
    }
    // fetch data
    static async fetchData(query = null, lang = 'en') {
        console.log('PrivacyPolicy::fetchData')
        return super.fetchData(query, lang, 1)
    }
}