
export const fileName = {
    ru: [
        "Политика в отношении Покупок",
        "Политика конфиденциальности",
        "Пользовательское соглашение",
        "Правила Cookies",
    ],
    en: [
        "Purchase Policy",
        "Privacy Policy",
        "Terms of Use",
        "Cookies policy",
    ]
}

export function getLocale(props) {
    const locale = props.match.params.local;
    return (locale in fileName) ? locale : 'en';
}
