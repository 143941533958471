import React from 'react';
import {AppContext} from "../../Contexts/AppContext";
import styled from "@emotion/styled";
import logo from './img/Logo.png'
import playStore from './img/playStore.png'

const MainBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .logo {
    height: 220px;
    margin-right: 50px;
    margin-top: 12px;
    @media screen and (max-width: 700px) {
      height: 120px;
      margin-right: 10px;
      margin-top: 0;
    }
  }
  
  & .content {
    width: 900px;
    padding-top: 180px;
    @media screen and (max-width: 899px) {
      width: 700px;
      padding-top: 0;
    }
    @media screen and (max-width: 700px) {
      max-width: 360px;
      padding-top: 0;
    }
    @media screen and (max-width: 280px) {
      max-width: 260px;
    }
    & > h1 {
      font-family: 'Inter-Bold',sans-serif !important;
      
      font-size: 56px;
      line-height: 68px;
      @media screen and (max-width: 899px) {
        font-size: 38px;
        line-height: 52px;
      }
      @media screen and (max-width: 280px) {
        font-size: 28px;
        line-height: 46px;
        
      }
      
      text-align: center;
      color: #FFFFFF;
      
      margin: 0;
      & > span {
        font-family: 'Inter-Bold',sans-serif !important;
        background: linear-gradient(91.54deg, #6066FF 0%, #767BFF 100%);
        border-radius: 12px;
        color: #000000;
        padding: 6px 16px;
      }
    }
    & > p {
      font-weight: 400;
      
      font-size: 32px;
      line-height: 39px;
      @media screen and (max-width: 899px) {
        font-size: 26px;
        line-height: 28px;
        padding: 16px;
      }
      @media screen and (max-width: 700px) {
        font-size: 18px;
        line-height: 22px;
      }
      text-align: center;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  
  & .store {
    font-weight: 700;
    
    font-size: 18px;
    line-height: 22px;
    @media screen and (max-width: 280px) {}
    
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    & > button {
      width: 297px;
      height: 88px;
      @media screen and (max-width: 280px) {
        width: 220px;
        height: 75px;
      }
      
      background: #000000;
      mix-blend-mode: normal;
      border-radius: 24px;
      padding: 22px 46px;
      cursor: pointer;
      border: 0;
    }
    & > p {
      margin-bottom: 58px;
      margin-top: 24px;
    }
  }
`;

const Header = () => {
    return (
        <img className={'logo'} src={logo} alt={'logo'}/>
    )
}
const Content = () => {
    return (
        <div className={'content'}>
            <h1>Полностью  <span>мобильная&nbsp;CRM</span></h1>
            <h1>для роста вашего бизнеса</h1>
            <p>Контролируйте клиентов, звонки и задачи без АТС и сложных настроек</p>
        </div>
    )
}
const Store = () => {
    return (
        <div className={'store'}>
            <button>
                <img width={'100%'} src={playStore} alt={'play-store'}/>
            </button>
            <p>Открытое тестирование</p>
        </div>
    )
}

export class Main extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        // component state
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                content: props.staticContext.content,
            };
        } else if (window.initial_state) {
            this.state = {
                isLoading: false,
                content: window.initial_state.content,
            };
        } else {
            this.state = {
                isLoading: true,
                content: '',
            };
        }
    }

    // fetch data
    static fetchData() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({content: 'in component'});
            }, 300);
        });
    }

    // when component mounts, fetch data
    componentDidMount() {
        if (this.state.isLoading) {
            Main.fetchData().then(content => {
                this.setState({
                    isLoading: false,
                    content: content.content,
                });
            });
        }
        this.inviteId = this.context.inviteId
    }

    render() {
        return (
            <MainBody>
                <Header/>
                <Content/>
                <Store/>
            </MainBody>
        );
    }
}
