import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {AppContext} from "../../Contexts/AppContext";
import {routers} from "../../../server/routes";
import {
    Switch,
    Route, useLocation, Redirect
} from "react-router-dom";
import Footer from './footer'
import mainBg from './img/mainBg.png'
import mainBgMini from './img/mainBgMini.png'
import {Cookie} from "../modals/cookie";

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const mdTheme = createTheme();


export class App extends React.Component {
    constructor(props) {
        super(props);

        this.setTosFileName = (name) => {
            this.setState({
                oldFileName: this.state.tosFileName,
                tosFileName: name
            })
        }

        this.state = {
            inviteId: 'def',

            oldFileName: '',
            tosFileName: '',
            changeFileName: this.setTosFileName,
        };
    }

    // render view
    render() {
        return (
            <ThemeProvider theme={mdTheme}>
                <AppContext.Provider value={this.state}>
                    <Box
                        component="main"
                        sx={{
                            background: 'linear-gradient(180deg, rgba(34, 36, 75, 0) 0%, #22244B 100%), linear-gradient(0deg, rgba(66, 198, 255, 0.22), rgba(66, 198, 255, 0.22))',
                            // height: '100vh',
                            padding: 0
                        }}
                    >
                        <Box
                            sx={{
                                background: `url(${mainBgMini}) no-repeat center center`,
                                backgroundSize: 'cover !important',
                                minHeight: '500px',
                                '@media screen and (min-width: 700px)': {
                                    backgroundSize: 'cover !important',
                                    background: `url(${mainBg}) no-repeat center center`,
                                },
                            }}
                        >
                            <Switch>
                                {
                                    routers.map((route, i) => (
                                        <RouteWithSubRoutes key={i} {...route} />
                                    ))
                                }
                            </Switch>
                        </Box>
                        <Footer/>
                    </Box>
                    <Cookie/>
                </AppContext.Provider>
            </ThemeProvider>
        );
    }
}

function RouteWithSubRoutes(route) {

    if (route.to !== undefined) {
        return (
            <Redirect to={route.to}/>
        )
    }

    return (
        <Route
            path={route.path}
            render={props => (
                <route.component {...props} routes={route.routes}/>
            )}
        />
    );
}