import React, {useEffect, useState} from "react";
import ModalBase from "./ModalBase";
import styled from "@emotion/styled";
import {css} from '@emotion/react'
import {useCookies} from "react-cookie";

const ColorStyle = props =>
    css`
      background: ${props.color};
    `

const ModalButton = styled.button`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 280px) {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;

  //width: 136px;
  height: 46px;

  background: #F9F9F9;
  border-radius: 12px;
  border: 0;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  ${ColorStyle};

  bottom: 16px;

  font-family: 'Inter-Medium', sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  @media screen and (min-width: 700px) {
    position: absolute;
    width: 248px;
    &.left {
      left: 16px;
      color: #35353D;
    }

    &.right {
      right: 16px;
      color: #FFFFFF;
    }
  }

  &.left {
    color: #35353D;
  }

  &.right {
    color: #FFFFFF;
  }
`;


export const Cookie = () => {
    const [cookies, setCookie, ] = useCookies(['cookie-acquainted']);
    const [modalState, setModalState] = useState(false)

    useEffect(() => {
        setTimeout(()=> {
            setModalState(cookies['cookie-acquainted'] !== 'true')
        }, 100)
    }, [cookies])

    if (modalState === false) {
        return ''
    }

    return (
        <>
            <ModalBase className={modalState ? '' : 'hidden'}>
                <div className={'modal-body'}>
                    <p>Настоящим, в соответствии с положениями Федерального закона РФ No 152-ФЗ «О персональных данных» от 27.07.2006 года, аналогичных нормативных актах
                        других стран свободно, своей волей и в своих интересах, выражаю свое безусловное согласие на обработку моих персональных данных компании Общество с
                        ограниченной ответственностью «Разумко» (ИНН 9719014224, юридический адрес: 107023, г Москва, ул. Большая Семёновская, 32), далее по тексту –
                        Оператор.</p>

                    <p>Персональные данные — любая информация, относящаяся к определенному или определяемому на основании такой информации физическому лицу.</p>

                    <p>Настоящее Согласие выдано мною на обработку следующих персональных данных:</p>

                    <ul>
                        <li>Информации о предпочитаемом пользователем или посетителем интернет-ресурса языке интерфейса интернет-страниц;</li>
                        <li>Информации о процессе взаимодействия пользователя с сайтом, источниках перехода.</li>
                    </ul>

                    <p>Согласие дано Оператору для совершения следующих действий с моими персональными данными с использованием средств автоматизации: сбор, запись,
                        систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, блокирование, удаление, уничтожение. Данное
                        согласие дается Оператору для обработки моих персональных данных в следующих целях:</p>

                    <ul>
                        <li>Улучшение работы интернет-ресурса и увеличение удобства пользователей.</li>
                        <li>Оценка эффективности маркетинговых инструментов.</li>
                    </ul>

                    <p>Настоящее согласие действует до момента его отзыва путем направления соответствующего уведомления на электронный адрес support@evernum.ru (для
                        Evernum) или support@evermeet.ru (для Evermeet). В случае отзыва мною согласия на обработку персональных данных Оператор вправе продолжить обработку
                        персональных данных без моего согласия при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11
                        Федерального закона No152-ФЗ «О персональных данных» от 27.07.2006 г. и аналогичных нормативных актах других стран.</p>
                    <br/>
                </div>
                <ModalButton className={'left'} color={'#F9F9F9'} onClick={() => {
                    setModalState(false)
                }}> Отклонить </ModalButton>
                <ModalButton className={'right'} color={'#0382F7'} onClick={() => {
                    setCookie('cookie-acquainted', modalState, {maxAge: 2592000})
                    setModalState(false)
                }}> Принять </ModalButton>
            </ModalBase>
        </>
    )
}