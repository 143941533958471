import React, {useEffect} from 'react';
import {useQuery} from "../app";

export const Deeplink = () => {
  const query = useQuery();
  const inviteId = query.get("id");
  const group_id = query.get("group_id");

  useEffect(() => {
    setTimeout(() => {
      window.open(`evernum://invite?id=${inviteId}&group_id=${group_id}`, '_top')
    }, 200)
  }, [])

  return (
      <a href={`evernum://invite?id=${inviteId}&group_id=${group_id}`}>Open APP</a>
  )
}
