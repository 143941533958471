import styled from "@emotion/styled";

const ModalBase = styled.div`

  &.hidden {
    transition-duration: 0.2s;
    transform: scale(0) translate(100%, 50%);
  }

  background: #FFFFFF;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  width: 546px;
  height: 374px;
  right: 16px;
  bottom: 16px;

  @media screen and (max-width: 700px) {
    width: calc(100% - 64px);
  }
  @media screen and (max-width: 280px) {
    width: 216px;
  }

  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.16));
  border-radius: 20px;

  padding: 24px 16px 16px;

  & .modal-body {
    width: 509px;
    height: calc(100% - 76px);
    @media screen and (max-width: 700px) {
      width: 100%;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 280px) {
      width: 200px;
      margin-bottom: 10px;
    }
    left: 0;
    top: 8px;

    overflow-y: auto;
    scrollbar-color: #d4aa70 #e4e4e4;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      border: 2px solid transparent;
      background-clip: content-box;
      background-color: #0382F6;
    }

    &:before {
      content: "";
      text-align: right;
      position: absolute;
      z-index: 10;
      right: 0;
      left: 0;
      height: 50px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%);
      pointer-events: none;
      bottom: 90px;
      @media screen and (max-width: 700px) {
        bottom: 136px;
      }
      @media screen and (max-width: 280px) {
        bottom: 136px;
      }
    }
  }
`

export default ModalBase