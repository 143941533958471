import React from 'react';

const {Main} = require('../src/components/main');
const {Invite} = require('../src/components/invite');
const {Tos} = require('../src/components/baseTos');
const {Cookies} = require('../src/components/cookiesTos');
const {PurchasePolicy} = require('../src/components/purchasePolicyTos');
const {PrivacyPolicy} = require('../src/components/privacyPolicyTos');

export const routers = [
    {
        path: '/',
        exact: true,
        component: Main,
    },
    {
        path: '/invite',
        exact: true,
        component: Invite,
    },
    {
        path: '/:local/tos',
        exact: true,
        component: Tos,
    },
    {
        path: '/tos',
        exact: true,
        component: Tos,
    },
    {
        path: '/:local/purchase_policy',
        exact: true,
        component: PurchasePolicy,
    },
    {
        path: '/purchase_policy',
        exact: true,
        component: PurchasePolicy,
    },
    {
        path: '/:local/privacy_policy',
        exact: true,
        component: PrivacyPolicy,
    },
    {
        path: '/privacy_policy',
        exact: true,
        component: PrivacyPolicy,
    },
    {
        path: '/:local/cookies',
        exact: true,
        component: Cookies,
    },
    {
        path: '/cookies',
        exact: true,
        component: Cookies,
    },
]
