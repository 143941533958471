import Typography from "@mui/material/Typography";
import React from "react";
import styled from "@emotion/styled";


const Copyright = (props) => {
    return (
        <div className={'footer-bottom-container'}>
            <Typography className={'footer-bottom'} variant="body2" color="text.secondary" align="center" {...props}>
                ООО «Разумко», ИНН 9719014224, 107023, Москва, ул. Большая Семёновская, 32
                {/*{new Date().getFullYear()}*/}
            </Typography>
        </div>
    );
}

const Menu = () => {
    return (
        <div className={'footer-menu'}>
            <a href={'/tos'}>Правила использования</a>
            <span className={'dot'}>·</span>
            <a href={'/purchase_policy'}>Политика покупок</a>
            <span className={'dot'}>·</span>
            <a href={'/privacy_policy'}>Конфеденциальность</a>
            <span className={'dot'}>·</span>
            <a href={'/cookies'}>Правила Cookies</a>
        </div>
    )
}

const FooterStyle = styled.div`
  background-color: #101013;

  & .footer-head {
    @media screen and (min-width: 700px) {
      height: 112px;
      gap: 30px;
    }
    @media screen and (max-width: 700px) {
      gap: 8px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      padding-bottom: 18px;
    }

    background: #16151D;

    & > a {
      font-weight: 400;

      font-size: 18px;
      line-height: 32px;
      @media screen and (max-width: 899px) {
      }

      color: #6066FF;
      text-decoration: none;

    }
  }

  & .footer-menu {
    @media screen and (min-width: 700px) {
      height: 80px;
    }
    gap: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: row;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      padding: 20px;
      & .dot {
        display: none;
      }
    }

    font-size: 18px;
    line-height: 32px;
    @media screen and (max-width: 899px) {
      font-size: 14px;
      line-height: 26px;
    }

    font-weight: 400;
    background: #13131B;
    color: #525767;
    text-align: center;

    & > a, a:visited {
      color: #525767;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: #6066FF;
        transition-duration: 1s;
      }
    }
  }

  & .footer-bottom-container {
    @media screen and (max-width: 700px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      padding: 24px;
    }

    @media screen and (min-width: 700px) {
      height: 80px;
      gap: 30px;
    
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }

    & .footer-bottom {
      background: #101013;
      color: #3F4555;
      font-weight: 400;
      padding: 0;

      font-size: 18px;
      line-height: 32px;
      @media screen and (max-width: 899px) {
        font-size: 14px;
        line-height: 28px;
      }
      @media screen and (max-width: 700px) {
        font-size: 14px;
        width: 235px;
        line-height: 21px;
      }
      text-align: center;
    }
  }
`;

const Footer = () => {
    return (
        <FooterStyle>
            <div className={'footer-head'}>
                <svg width="190" height="50" viewBox="0 0 190 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M32.5753 16.0435C34.3577 16.0435 35.952 16.4074 37.3581 17.1352C38.7642 17.863 39.8535 18.8739 40.6259 20.1677C41.4181 21.4616 41.8142 22.9375 41.8142 24.5953C41.8142 26.2531 41.4181 27.7391 40.6259 29.0532C39.8535 30.3673 38.7642 31.3882 37.3581 32.1161C35.952 32.8439 34.3577 33.2078 32.5753 33.2078C30.9513 33.2078 29.4957 32.9349 28.2084 32.389C26.9211 31.8229 25.8516 31.0243 25 29.9933L28.2381 26.9607C29.3867 28.2546 30.7533 28.9015 32.3376 28.9015C33.2883 28.9015 34.1002 28.6893 34.7736 28.2647C35.4668 27.8401 35.9718 27.2437 36.2887 26.4755H30.2878V22.6545H36.259C35.9421 21.9266 35.4371 21.3606 34.7439 20.9562C34.0705 20.5317 33.2684 20.3194 32.3376 20.3194C30.7533 20.3194 29.3867 20.9663 28.2381 22.2602L25 19.258C25.8516 18.2269 26.9211 17.4384 28.2084 16.8926C29.4957 16.3265 30.9513 16.0435 32.5753 16.0435Z"
                        fill="#525767"/>
                    <path
                        d="M57.4284 24.2314C59.2703 24.8581 60.1912 26.152 60.1912 28.1131C60.1912 29.5889 59.6267 30.7514 58.4979 31.6005C57.369 32.4294 55.7054 32.8439 53.5071 32.8439H44.4465V16.4074H53.0912C55.1905 16.4074 56.8145 16.7814 57.9632 17.5294C59.1316 18.2774 59.7159 19.3287 59.7159 20.6833C59.7159 21.4718 59.5178 22.1692 59.1217 22.7758C58.7256 23.3823 58.1612 23.8675 57.4284 24.2314ZM49.4967 23.0184H52.5862C53.8933 23.0184 54.5469 22.523 54.5469 21.5324C54.5469 20.5822 53.8933 20.1071 52.5862 20.1071H49.4967V23.0184ZM53.0021 29.1138C54.3686 29.1138 55.0519 28.6084 55.0519 27.5975C55.0519 26.5867 54.3884 26.0813 53.0615 26.0813H49.4967V29.1138H53.0021Z"
                        fill="#525767"/>
                    <path
                        d="M71.5683 28.7196C72.3406 28.7196 73.0041 28.6084 73.5586 28.386C74.133 28.1636 74.7073 27.7997 75.2816 27.2943L78.2226 30.4481C76.6977 32.2273 74.4201 33.1168 71.39 33.1168C69.5086 33.1168 67.8549 32.7529 66.429 32.0251C65.003 31.2973 63.894 30.2864 63.1018 28.9925C62.3294 27.6986 61.9432 26.2329 61.9432 24.5953C61.9432 22.9779 62.3294 21.5324 63.1018 20.2587C63.8742 18.9648 64.9337 17.954 66.2804 17.2262C67.6469 16.4983 69.1719 16.1344 70.8553 16.1344C72.4199 16.1344 73.8458 16.468 75.1331 17.1352C76.4402 17.7821 77.48 18.7424 78.2523 20.0161C79.0247 21.2696 79.4109 22.7656 79.4109 24.5043L67.9143 26.7484C68.5282 28.0625 69.7462 28.7196 71.5683 28.7196ZM70.885 20.2284C69.9146 20.2284 69.1224 20.5418 68.5084 21.1685C67.8945 21.775 67.5479 22.6545 67.4687 23.8068L74.0042 22.5028C73.8062 21.8154 73.4299 21.2696 72.8754 20.8652C72.3208 20.4407 71.6574 20.2284 70.885 20.2284Z"
                        fill="#6066FF"/>
                    <path
                        d="M90.7174 16.1344C92.48 16.1344 94.0149 16.4781 95.322 17.1655C96.6291 17.8529 97.6293 18.8435 98.3224 20.1374C99.0354 21.4111 99.3919 22.9072 99.3919 24.6256C99.3919 26.3239 99.0651 27.8199 98.4115 29.1138C97.758 30.3875 96.8371 31.3781 95.6488 32.0857C94.4605 32.7731 93.0841 33.1168 91.5195 33.1168C89.6777 33.1168 88.2319 32.5406 87.1823 31.3882V38.7271H81.538V24.6863C81.538 22.988 81.9143 21.492 82.6668 20.1981C83.4194 18.9042 84.4889 17.9034 85.8752 17.1958C87.2615 16.4882 88.8756 16.1344 90.7174 16.1344ZM90.4501 28.5376C91.4601 28.5376 92.2721 28.1838 92.886 27.4762C93.5198 26.7484 93.8367 25.7982 93.8367 24.6256C93.8367 23.453 93.5198 22.5129 92.886 21.8053C92.2721 21.0775 91.4601 20.7136 90.4501 20.7136C89.44 20.7136 88.628 21.0775 88.0141 21.8053C87.4002 22.5129 87.0932 23.453 87.0932 24.6256C87.0932 25.8184 87.3903 26.7686 87.9844 27.4762C88.5983 28.1838 89.4202 28.5376 90.4501 28.5376Z"
                        fill="#525767"/>
                    <path d="M102.12 16.4074H107.734V22.5028H113.527V16.4074H119.172V32.8439H113.527V27.082H107.734V32.8439H102.12V16.4074Z" fill="#525767"/>
                    <path
                        d="M139.822 16.4074V29.8416C139.822 32.8944 139.02 35.179 137.416 36.6952C135.832 38.2317 133.544 39 130.554 39C128.989 39 127.524 38.818 126.157 38.4541C124.81 38.0902 123.662 37.5545 122.711 36.8469L124.761 32.8136C125.395 33.3594 126.187 33.784 127.138 34.0872C128.108 34.4107 129.068 34.5724 130.019 34.5724C131.445 34.5724 132.495 34.2389 133.168 33.5717C133.841 32.9045 134.178 31.9139 134.178 30.5998V30.2359C132.97 31.4085 131.465 31.9948 129.663 31.9948C127.583 31.9948 125.92 31.368 124.672 30.1146C123.444 28.8611 122.83 26.9809 122.83 24.474V16.4074H128.474V23.5339C128.474 24.7874 128.702 25.6971 129.158 26.2632C129.613 26.8293 130.277 27.1123 131.148 27.1123C132.039 27.1123 132.762 26.7889 133.317 26.1419C133.891 25.495 134.178 24.5245 134.178 23.2306V16.4074H139.822Z"
                        fill="#525767"/>
                    <path
                        d="M159.173 32.8439V24.2011L155.044 31.3882H152.637L148.627 24.0798V32.8439H143.547V16.4074H149.489L153.974 24.9895L158.906 16.4074H164.223L164.253 32.8439H159.173Z"
                        fill="#525767"/>
                    <path
                        d="M167.227 12.954C166.632 12.954 166.096 12.8286 165.62 12.5778C165.149 12.3216 164.778 11.9671 164.508 11.5146C164.237 11.062 164.102 10.5495 164.102 9.97702C164.102 9.40451 164.237 8.89199 164.508 8.43944C164.778 7.98689 165.149 7.63521 165.62 7.38439C166.096 7.12813 166.632 7 167.227 7C167.747 7 168.215 7.09269 168.632 7.27807C169.049 7.46345 169.395 7.73062 169.671 8.07958L168.648 9.01194C168.28 8.56484 167.834 8.34129 167.309 8.34129C167 8.34129 166.724 8.40945 166.481 8.54576C166.242 8.68207 166.056 8.87563 165.92 9.12644C165.79 9.3718 165.726 9.65532 165.726 9.97702C165.726 10.2987 165.79 10.585 165.92 10.8358C166.056 11.0811 166.242 11.272 166.481 11.4083C166.724 11.5446 167 11.6127 167.309 11.6127C167.834 11.6127 168.28 11.3892 168.648 10.9421L169.671 11.8745C169.395 12.2234 169.049 12.4906 168.632 12.676C168.215 12.8613 167.747 12.954 167.227 12.954Z"
                        fill="#525767"/>
                    <path
                        d="M172.872 11.3183H171.995V12.8395H170.388V7.1145H172.986C173.5 7.1145 173.947 7.20174 174.325 7.37622C174.704 7.54524 174.997 7.7906 175.202 8.11229C175.408 8.42853 175.511 8.80202 175.511 9.23276C175.511 9.64715 175.413 10.0097 175.218 10.3205C175.029 10.6259 174.756 10.8658 174.399 11.0402L175.624 12.8395H173.903L172.872 11.3183ZM173.887 9.23276C173.887 8.96559 173.803 8.7584 173.635 8.61119C173.468 8.46397 173.219 8.39036 172.888 8.39036H171.995V10.067H172.888C173.219 10.067 173.468 9.9961 173.635 9.85434C173.803 9.70712 173.887 9.49993 173.887 9.23276Z"
                        fill="#525767"/>
                    <path
                        d="M183.047 7C183.718 7 184.24 7.20174 184.614 7.60522C184.993 8.00324 185.182 8.5812 185.182 9.33908V12.8395H183.574V9.38816C183.574 9.04465 183.485 8.78566 183.307 8.61119C183.128 8.43126 182.89 8.34129 182.592 8.34129C182.273 8.34129 182.018 8.43671 181.829 8.62754C181.645 8.81293 181.553 9.09373 181.553 9.46994V12.8395H179.945V9.38816C179.945 9.04465 179.862 8.78566 179.694 8.61119C179.531 8.43126 179.307 8.34129 179.02 8.34129C178.711 8.34129 178.465 8.43671 178.281 8.62754C178.097 8.81293 178.005 9.09373 178.005 9.46994V12.8395H176.398V7.1145H177.956V7.75243C178.13 7.50707 178.351 7.32169 178.622 7.19629C178.898 7.06543 179.217 7 179.58 7C179.954 7 180.278 7.06815 180.554 7.20447C180.836 7.34078 181.063 7.53979 181.236 7.8015C181.431 7.53979 181.686 7.34078 181.999 7.20447C182.313 7.06815 182.662 7 183.047 7Z"
                        fill="#525767"/>
                </svg>
                <a href="mailto:support@evernum.ru">support@evernum.ru</a>
            </div>
            <Menu/>
            <Copyright sx={{pt: 4}}/>
        </FooterStyle>
    )
}


export default Footer;