import React from 'react';
import {AppContext} from "../../Contexts/AppContext";
import {Deeplink} from "./deeplink";

export class Invite extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        // component state
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                content: props.staticContext.content,
            };
        } else if (window.initial_state) {
            this.state = {
                isLoading: false,
                content: window.initial_state.content,
            };
        } else {
            this.state = {
                isLoading: true,
                content: '',
            };
        }
    }

    // fetch data
    static fetchData() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({content: 'in component inviteID'});
            }, 300);
        });
    }

    // when component mounts, fetch data
    componentDidMount() {
        if (this.state.isLoading) {
            Invite.fetchData().then(content => {
                this.setState({
                    isLoading: false,
                    content: content.content,
                });
            });
        }
        this.inviteId = this.context.inviteId
    }

    render() {
        return (
            this.state.isLoading ? 'loading...' : (
                <Deeplink/>
                // <div className="content" dangerouslySetInnerHTML={{__html: this.state.content}}></div>
            )
        );
    }
}
